import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CamSizes, CamState } from '@camelot/styles';
import { TemplateModalContainer, TemplateModalContainerData } from '@camelot/ui';

import { IQuotationsService } from 'src/app/services/billings/IQuotationsService';
import { QuotationStatus } from 'src/app/services/billings/dto/quotation/quotation-status';

@Component({
  selector: 'app-quotation-version-status',
  templateUrl: './quotation-version-status.component.html',
  styleUrls: ['./quotation-version-status.component.scss'],
})
export class QuotationVersionStatusComponent {
  @Input()
  versionId!: string;

  @Input()
  status!: QuotationStatus;

  @Input()
  isSignatureRequired: boolean | null = null;

  @Input()
  size: CamSizes = 'md';

  @Input()
  quotationsService!: IQuotationsService;

  get state(): CamState {
    if (this.isSignatureRequired) {
      return 'inactive';
    }
    return 'classic';
  }
  public readonly quotationStatus = QuotationStatus;

  @ViewChild('refuseTemplate', { read: TemplateRef })
  refuseTemplate!: TemplateRef<void>;

  @ViewChild('acceptTemplate', { read: TemplateRef })
  acceptTemplate!: TemplateRef<void>;

  constructor(public modal: MatDialog) {}

  public refuse() {
    this.modal.open<TemplateModalContainer, TemplateModalContainerData>(TemplateModalContainer, {
      data: {
        template: this.refuseTemplate,
        style: 'classic',
      },
    });
  }

  public accept() {
    this.modal.open<TemplateModalContainer, TemplateModalContainerData>(TemplateModalContainer, {
      data: {
        template: this.acceptTemplate,
        style: 'classic',
      },
    });
  }

  public close() {
    this.modal.closeAll();
  }
}
