<cam-loader [isLoading]="this.requestState.isLoading()">
  <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
    <div class="version-container" *ngLet="this.version$ | async as version">
      <ng-container *ngIf="version">
        <div class="summary">
          <app-quotations-version-summary
            [version]="version"
            [quotationsService]="this.quotationsService"
          ></app-quotations-version-summary>
        </div>
        <div class="description" *ngIf="version.description">
          <cam-title [level]="2">{{ 'billings.description-title' | translate }}</cam-title>
          <cam-empty
            class="empty-description"
            [isEmpty]="!version.description"
            [isLight]="false"
            icon="task-canceled"
            iconSize="xl"
            [text]="''"
          >
            <cam-expandable-text>
              <div [innerHTML]="version.description"></div>
            </cam-expandable-text>
          </cam-empty>
        </div>
        <div class="detail">
          <div class="flex-start">
            <cam-title [level]="2">
              <span class="d-md-none">{{ 'billings.detail-title-mobile' | translate }}</span>
              <span class="d-none d-md-block">{{ 'billings.detail-title-desktop' | translate }}</span>
            </cam-title>
            <cam-grid-control
              class="d-none d-md-block"
              gridId="invoiceRow"
              [show]="{ filters: false, search: false, switchView: false }"
            ></cam-grid-control>
          </div>
          <app-billing-detail [item]="version" [service]="this.service"></app-billing-detail>
        </div>
        <div class="extra">
          <div class="download" *ngIf="version.downloadUriExcel || version.downloadUriPDF">
            <div class="title">{{ 'billings.download-title' | translate }}</div>
            <div class="buttons">
              <cam-button
                [style]="'secondary'"
                *ngIf="version.downloadUriExcel"
                (action)="this.downloadFile(version.downloadUriExcel)"
              >
                <div class="d-flex">
                  <cam-font-icon name="download"></cam-font-icon>
                  {{ 'billings.quotations.version.excel' | translate }}
                </div>
              </cam-button>
              <cam-button
                [style]="'secondary'"
                *ngIf="version.downloadUriPDF"
                (action)="this.downloadFile(version.downloadUriPDF)"
              >
                <div class="d-flex">
                  <cam-font-icon name="download"></cam-font-icon>
                  {{ 'billings.pdf' | translate }}
                </div>
              </cam-button>
            </div>
          </div>
          <div class="contact">
            <ng-container *ngIf="version.devisorId">
              <cam-title [level]="3" [isBold]="true">{{ 'billings.quotations.version.contact' | translate }}</cam-title>
              <cam-contact-card [userId]="version.devisorId"></cam-contact-card>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </cam-error>
</cam-loader>
