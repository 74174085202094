import { Injectable } from '@angular/core';

import { combineLatest, filter, map, tap } from 'rxjs';

import { CamBaseService, GraphEndpoint, HandleComplexRequest, HandleSimpleRequest } from '@camelot/server';
import { isNonNullable } from '@camelot/utils';

import { IQuotationsService } from './IQuotationsService';
import { RefusalInfos } from './dto/mutation/RefusalInfos';
import { Quotation } from './dto/quotation/quotation';
import { QuotationRow } from './dto/quotation/row';
import { QuotationVersion } from './dto/quotation/version';
import {
  GET_ALL_VERSIONS,
  GET_QUOTATION,
  GET_QUOTATIONS_BY_PROJECT,
  GET_QUOTATION_PARENT_ROWS,
  GET_QUOTATION_ROWS,
  GET_QUOTATION_ROW_CHILDREN,
  GET_QUOTATION_VERSIONS,
  GET_VERSION,
  GET_VERSIONS_BY_PROJECT,
  GET_QUOTATION_SIGNATURE_LINK,
} from './queries';
import {QUOTATION_VERSION_REFUSED, QUOTATION_VERSION_SIGNED, READ_QUOTATION_VERSION} from './userMutations';
import {QuotationSignature} from "./dto/quotation/quotation-signature";

const graphEndpoint: GraphEndpoint = {
  clientName: 'quotationService',
  endpoint: 'quotation',
};

@Injectable({
  providedIn: 'root',
})
export class AppQuotationsService extends CamBaseService implements IQuotationsService {
  public clientName = graphEndpoint.clientName;

  public quotationsByProject = new HandleComplexRequest<Quotation[]>();
  public dashboard = new HandleComplexRequest<QuotationVersion[]>();
  public home = new HandleSimpleRequest<QuotationVersion[]>();

  public quotation = new HandleComplexRequest<Quotation>();
  public version = new HandleComplexRequest<QuotationVersion>();
  public quotationParentRows = new HandleComplexRequest<QuotationRow[]>();
  public quotationRows = new HandleComplexRequest<QuotationRow[]>();
  public quotationSignature = new HandleComplexRequest<QuotationSignature>();

  constructor() {
    super();

    super.registerRoutes({graphEndpoint: graphEndpoint});
  }

  public fetchQuotationsByProject$(projectId: string) {
    return this.quotationsByProject.fetch(
      projectId,
      this._graphService.fetchQueryList<Quotation>(
        GET_QUOTATIONS_BY_PROJECT(projectId),
        'quotationsByProject',
        this.clientName
      )
    );
  }

  public fetchQuotation$(id: string) {
    return this.quotation.fetch(
      id,
      this._graphService
        .fetchQuery<Quotation[]>(GET_QUOTATION(id), 'quotations', this.clientName)
        .pipe(map(data => data[0]))
    );
  }

  public fetchQuotationRows$(versionId: string) {
    return this.quotationRows.fetch(
      versionId,
      this._graphService.fetchQuery<QuotationRow[]>(GET_QUOTATION_ROWS(versionId), 'quotationRows', this.clientName)
    );
  }

  public fetchDashboardQuotationVersions$(id: string) {
    return this.dashboard.fetch(
      id,
      this._graphService
        .fetchPagedQueryList<QuotationVersion>(GET_VERSIONS_BY_PROJECT(id, 5), 'quotationVersions', this.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchAllVersions$() {
    return this.home.fetch(
      this._graphService
        .fetchPagedQueryList<QuotationVersion>(GET_ALL_VERSIONS(), 'quotationVersions', this.clientName)
        .pipe(map(data => data.items ?? []))
    );
  }

  public fetchQuotationVersions$(id: string) {
    return this.quotation.fetch(
      id,
      this._graphService.fetchQuery<Quotation>(
        GET_QUOTATION_VERSIONS(id),
        'quotationVersionsByQuotation',
        this.clientName
      )
    );
  }

  public getVersions(ids: string[]) {
    return combineLatest(ids.map(id => this.version.get$(id)));
  }

  public fetchVersions$(ids: string[]) {
    return combineLatest(
      ids.map(id =>
        this.version.fetch(
          id,
          this._graphService
            .fetchQuery<QuotationVersion>(GET_VERSION(id), 'quotationVersionById', this.clientName)
            .pipe(filter(isNonNullable))
        )
      )
    );
  }

  public fetchQuotationParentRows$(id: string) {
    return this.quotationParentRows.fetch(
      id,
      this._graphService
        .fetchQueryList<QuotationRow>(GET_QUOTATION_PARENT_ROWS(id), 'quotationParentRows', this.clientName)
        .pipe(
          map(list =>
            list.map(item => ({
              ...item,
              ...{ label: item.index.toString() },
            }))
          )
        )
    );
  }

  public fetchQuotationRowChildren$(id: string, parent?: QuotationRow) {
    return this.quotationParentRows.fetch(
      id,
      this._graphService
        .fetchQueryList<QuotationRow>(GET_QUOTATION_ROW_CHILDREN(id), 'quotationRowChildren', this.clientName)
        .pipe(
          map(list =>
            list.map(item => ({
              ...item,
              ...{ label: `${parent?.label}.${item.index}` },
            }))
          )
        )
    );
  }

  public setQuotationVersionSigned$(quotationVersionId: string) {
    return this._graphService
      .mutate<QuotationVersion>(QUOTATION_VERSION_SIGNED(quotationVersionId), 'quotationVersionSigned', this.clientName)
      .pipe(tap(version => this.version.update(quotationVersionId, version)));
  }

  public setQuotationVersionRefused$(quotationVersionId: string, refusalInfos: RefusalInfos) {
    return this._graphService
      .mutate<QuotationVersion>(
        QUOTATION_VERSION_REFUSED(quotationVersionId, refusalInfos),
        'quotationVersionRefused',
        this.clientName
      )
      .pipe(tap(version => this.version.update(quotationVersionId, version)));
  }

  public isRead$(id: string) {
    return this._graphService.mutate<boolean>(READ_QUOTATION_VERSION(id), 'quotationVersionRead', this.clientName);
  }

  public getSignatureLink$(quotationVersionId: string, successUri: string, errorUri: string, declineUri: string){
    return this.quotationSignature.fetch(
      quotationVersionId,
      this._graphService.fetchQuery<QuotationSignature>(GET_QUOTATION_SIGNATURE_LINK(quotationVersionId, successUri, errorUri, declineUri), 'signatureLink', this.clientName)
    )
  }
}
