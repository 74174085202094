<div class="flex-column g-space-lg full-width">
  <div class="ta-c flex-column g-space-md">
    <cam-font-icon name="infos" type="lg"></cam-font-icon>
    <cam-title [level]="3">
      {{ 'billings.quotations.version.status.signed.description' | translate }}
    </cam-title>
  </div>
  <div class="cgu-container">
    <cam-layout-side class="d-flex full-width">
      <cam-layout-side-content>
        <cam-loader [isLoading]="this.requestState.isLoading()">
          <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
            <div class="p-space-md">
              <cam-sale (acceptation)="this.cguAcceptation($event)"></cam-sale>
            </div>
          </cam-error>
        </cam-loader>
      </cam-layout-side-content>
      <cam-layout-side-cta [background]="false">
        <div class="justify-end align-center g-space-md">
          <cam-link (action)="this.cancel()" icon="close-tool" size="sm">
            {{ 'billings.quotations.version.status.signed.cancel' | translate }}
          </cam-link>
          <cam-button (action)="this.send()" [state]="this.isValid && !this.requestState.isLoading() ? 'classic' : 'disabled'">
            <div class="flex-row">
              <cam-font-icon name="check-line"></cam-font-icon>
              {{ 'billings.quotations.version.status.signed.signed' | translate }}
            </div>
          </cam-button>
        </div>
      </cam-layout-side-cta>
    </cam-layout-side>
  </div>
</div>
