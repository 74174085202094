<div class="summary">
  <app-quotation-loading>
    <div class="summary-content">
      <div class="price-information">
        <app-billing-amount class="label" [amount]="this.versionPrice" display="label"></app-billing-amount>
        <app-billing-amount class="price" [amount]="this.versionPrice" display="amount"></app-billing-amount>
        <div class="small-information">
          <app-billing-amount [amount]="this.versionPrice" display="amount" [inverse]="true"></app-billing-amount>
          <app-billing-amount
            class="small-information-label"
            [amount]="this.versionPrice"
            display="label"
            [inverse]="true"
          ></app-billing-amount>
        </div>
      </div>
      <div class="extra-information">
        <div>
          <div class="title">{{ 'billings.quotations.version.summary.payment-term' | translate }}</div>
          <div class="value">{{ this.version.paymentTerm }}</div>
        </div>
        <div>
          <div class="title">{{ 'billings.quotations.version.summary.due-date' | translate }}</div>
          <div class="value">{{ this.version.dueDate | date : 'shortDate' }}</div>
        </div>
        <div>
          <div class="title">{{ 'billings.quotations.version.summary.discount' | translate }}</div>
          <div class="value">{{ this.version.discount | currency }}</div>
        </div>
      </div>
      <div class="status-information">
        <app-quotation-version-status
          size="lg"
          [status]="this.version.status"
          [isSignatureRequired]="this.version.isSignatureRequired"
          [versionId]="this.version.id"
          [quotationsService]="this.quotationsService"
        ></app-quotation-version-status>
      </div>
    </div>
  </app-quotation-loading>
</div>
