import { GraphSchema } from '@camelot/server';
import { User } from '@camelot/user';

import { BillingItem, billingItemProps } from '../common/item';
import { Quotation } from './quotation';
import { QuotationStatus } from './quotation-status';

export interface QuotationVersion extends BillingItem {
  version: number;
  status: QuotationStatus;
  date: string;
  acceptanceDate: string;
  paymentTerm: string;

  downloadUriExcel: string;

  rowsMigratedCount: number;
  fullyMigrated: number;

  discount: number;

  quotation?: Quotation;

  isSignatureRequired: boolean;
}

export interface QuotationVersionExtended extends QuotationVersion {
  devisor?: User;
}

const props: (keyof QuotationVersion)[] = [
  'version',
  'status',
  'date',
  'acceptanceDate',
  'paymentTerm',
  'downloadUriExcel',
  'rowsMigratedCount',
  'fullyMigrated',
  'discount',
  'quotation',
  'isSignatureRequired',
  'devisorId',
];
export const quotationVersionProps = new GraphSchema<QuotationVersion>(props.concat(billingItemProps));
