<app-layout-light>
  <app-layout-title> {{ 'quotations.visitor.pages.title' | translate }} </app-layout-title>
  <app-layout-content>
    <div class="quotations-version-container">
      <app-quotations-version
        *ngIf="this.version"
        [versionId]="this.version"
        [quotationsService]="this.quotationsService"
      ></app-quotations-version>
    </div>
  </app-layout-content>
</app-layout-light>
