import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';

import { QuotationVisitorViewPage } from './pages/visitor/quotations/view/view.component';

export enum EBillingsVisitorRoute {
  billing = 'visitor-billings',
  view = 'view',
}

export class AppBillingsVisitorRoutingModule {
  public static loadRoutes() {
    CamRoutes.addRoute({
      key: EBillingsVisitorRoute.billing,
      url: 'visitor-billings',
      children: [
        {
          key: EBillingsVisitorRoute.view,
          url: 'view/:version',
        },
      ],
    });
  }

  public static getRouting(): Routes {
    return [
      {
        path: CamRoutes.getUrl([EBillingsVisitorRoute.billing, EBillingsVisitorRoute.view]),
        component: QuotationVisitorViewPage,
      },
    ];
  }
}
