import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';

import { map } from 'rxjs/operators';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';
import { AppQuotationsService } from 'src/app/services/billings/quotations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class QuotationViewPage extends BasePage implements OnInit {
  public id: string = '';
  public version: string = '';

  public activeVersion: QuotationVersion | null = null;

  get isNew$() {
    return this.quotationsService.quotation
      .get$(this.id)
      .pipe(map(quotation => !!quotation.versions?.find(version => version.isNew)));
  }
  get quotation$() {
    return this.quotationsService.quotation.get$(this.id);
  }

  readonly quotationsService = inject(AppQuotationsService);

  constructor() {
    super();
  }

  ngOnInit() {
    this._registerSubscription(
      this._getPathParams({ id: '', version: '' }).subscribe(({ id, version }) => {
        this.id = id;
        this.version = version;
      })
    );
    this._fetch();
  }

  public select(version: QuotationVersion) {
    this.activeVersion = version;
    if (this.activeVersion.isNew) {
      this.quotationsService.isRead$(this.activeVersion.id).subscribe();
    }
  }

  private _fetch() {
    this.requestState.asked();
    this.quotationsService.fetchQuotation$(this.id).subscribe({
      complete: () => {
        const versions = this.quotationsService.quotation.get(this.id)?.versions ?? [];
        const version = versions.find(v => v.id === this.version) ?? versions[0];

        if (version) {
          this.select(version);
        }

        this.requestState.completed();
      },
      error: (error: HttpErrorResponse) => {
        this.requestState.onError(error.status, error.statusText);
      },
    });
  }
}
