<div *ngLet="(this.data$ | async) || [] as data">
  <cam-loader [isLoading]="this.requestState.isLoading()">
    <cam-error [message]="this.requestState.getErrorMessage()" [code]="this.requestState.getErrorStatus()">
      <cam-empty [isEmpty]="data.length === 0">
        <ng-container *ngIf="this.breakpoints.isMobile$ | async">
          <div class="see-detail-container">
            <cam-button (action)="this.openDetail(data)">
              <div class="align-center m-space-xs">
                <cam-font-icon class="mr-space-xs" name="search"></cam-font-icon>
                {{ 'billings.detail.see-detail' | translate }}
              </div>
            </cam-button>
          </div>
          <ng-container *ngIf="this.hasDocuments">
            <cam-title [level]="3">
              {{ 'billings.detail.see-documents-title' | translate }}
            </cam-title>
            <cam-button (action)="this.openDetailDocuments()" [style]="'secondary'">
              <div class="align-center m-space-xs">
                <cam-font-icon class="mr-space-xs" name="search"></cam-font-icon>
                {{ 'billings.detail.see-documents' | translate }}
              </div>
            </cam-button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="this.breakpoints.isDesktop$ | async">
          <ng-container
            [ngTemplateOutlet]="this.gridTemplate"
            [ngTemplateOutletContext]="{
              data: data
            }"
          >
          </ng-container>
        </ng-container>
      </cam-empty>
    </cam-error>
  </cam-loader>
</div>

<ng-template #gridTemplate let-data="data">
  <cam-ag-grid
    *ngIf="data && data.length > 0"
    [data]="data"
    defaultDisplay="grid"
    gridId="invoiceRow"
    [treeData]="this.treeData"
    [fieldsOverrides]="this.fieldsOverrides"
    [gridOptions]="{
      domLayout: 'autoHeight',
      isFullWidthRow: this.isFullWidthRow,
      fullWidthCellRenderer: this.fullWidthCellRenderer,
      getRowClass: this.getRowClass,
    }"
    [colOptions]="{ sortable: false }"
  ></cam-ag-grid>

  <ng-container *ngIf="(this.breakpoints.isMobile$ | async) && this.item.downloadUriPDF">
    <div class="download mt-space-md">
      <div class="title">{{ 'billings.download-mobile-content' | translate }}</div>
      <div class="buttons">
        <cam-button [style]="'secondary'" (action)="this.downloadFile(this.item.downloadUriPDF)">
          <div class="d-flex">
            <cam-font-icon name="download"></cam-font-icon>
            {{ 'billings.pdf' | translate }}
          </div>
        </cam-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<cam-layout-full-panel *ngIf="this.selectedRow?.nbDocuments ?? 0 > 0" (closeEvent)="this.close()" width="800px">
  <div class="m-space-md">
    <cam-title [level]="2">{{ 'billings.documents.title' | translate }}</cam-title>
    <cam-guard level="authenticated">
      <app-billings-document
        [ids]="this.selectedRow?._brutContent?.documentIds ?? []"
        [rowTitle]="this.selectedRow?.name ?? ''"
      ></app-billings-document>
    </cam-guard>
  </div>
</cam-layout-full-panel>

<ng-template #documentsTemplate>
  <cam-title [level]="2">{{ 'billings.documents.title' | translate }}</cam-title>
  <app-billings-documents-all-rows [item]="this.item"></app-billings-documents-all-rows>
</ng-template>
