<div>
  <div class="header align-center">
    <div class="col-2">
      <cam-logo type="oneline"></cam-logo>
    </div>

    <cam-title>
      <ng-content select="app-layout-title"></ng-content>
    </cam-title>
    <div class="col-2">
      <cam-switch-language-cta></cam-switch-language-cta>
    </div>
  </div>
  <div class="container">
    <ng-content select="app-layout-content"></ng-content>
  </div>
</div>
