import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { InputBase } from '@camelot/form-model';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { IQuotationsService } from 'src/app/services/billings/IQuotationsService';
import { AppBillingFormService } from 'src/app/services/billings/form/form.service';

@Component({
  selector: 'app-quotation-version-refused',
  templateUrl: './refused.component.html',
  styleUrls: ['./refused.component.scss'],
})
export class RefusedComponent extends BaseComponent {
  @Input()
  versionId!: string;

  @Input()
  quotationsService!: IQuotationsService;

  @Output()
  closed = new EventEmitter();

  public form: InputBase<any>[] | null = null;

  constructor(private _formService: AppBillingFormService) {
    super();
    this.form = this._formService.getVersionRefusedForm();
  }

  public send(data: any) {
    this.requestState.asked();
    const values = this._formService.formatVersionRefusedForm(data);

    this.quotationsService.setQuotationVersionRefused$(this.versionId, values).subscribe({
      complete: () => {
        this.requestState.completed();
        this.closed.emit();
      },
      error: (error: HttpErrorResponse) => this.requestState.onError(error.status, error.statusText),
    });
  }
}
