<ng-container [ngSwitch]="this.status">
  <ng-container *ngSwitchCase="this.quotationStatus.Sent">
    <div class="sent-container" *ngIf="this.isSignatureRequired !== null">
      <cam-link size="sm" [state]="this.state" (action)="this.refuse()">
        {{ 'billings.quotations.version.cta.refuse' | translate }}
      </cam-link>
      <cam-button size="large" [state]="this.state" (action)="this.accept()" icon="check-line">
        {{ 'billings.quotations.version.cta.accept' | translate }}
      </cam-button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="this.quotationStatus.Canceled">
    <cam-label [size]="this.size" type="secondary">{{ 'billings.status.canceled' | translate }}</cam-label>
  </ng-container>

  <ng-container *ngSwitchCase="this.quotationStatus.Expired">
    <cam-label [size]="this.size" type="secondary">{{ 'billings.status.expired' | translate }}</cam-label>
  </ng-container>

  <ng-container *ngSwitchCase="this.quotationStatus.Refused">
    <cam-label [size]="this.size" type="alert">{{ 'billings.status.refused' | translate }}</cam-label>
  </ng-container>

  <ng-container *ngSwitchCase="this.quotationStatus.Signed">
    <cam-label [size]="this.size" type="success">{{ 'billings.status.signed' | translate }}</cam-label>
  </ng-container>
</ng-container>

<ng-template #refuseTemplate>
  <app-quotation-version-refused
    [versionId]="this.versionId"
    (closed)="this.close()"
    [quotationsService]="this.quotationsService"
  ></app-quotation-version-refused>
</ng-template>
<ng-template #acceptTemplate>
  <app-quotation-version-signed
    [versionId]="this.versionId"
    (closed)="this.close()"
    [quotationsService]="this.quotationsService"
  ></app-quotation-version-signed>
</ng-template>
