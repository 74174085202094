<app-layout-first-level>
  <app-layout-title>
    {{ 'base.home.title' | translate }}
  </app-layout-title>
  <app-layout-content *ngLet="this.profileName$ | async as userInfo">
    <div *ngIf="userInfo" class="flex-row align-center g-space-md mb-space-md">
      <cam-user-logo [userInfo]="userInfo" size="lg"></cam-user-logo>
      <cam-title [level]="3">{{ 'base.home.hello' | translate : { name: userInfo.naming?.firstName } }} 👋</cam-title>
    </div>

    <div class="flex-column g-space-xl">
      <app-home-project></app-home-project>
      <app-quotation></app-quotation>
      <app-invoice></app-invoice>
    </div>
  </app-layout-content>
  <cam-layout-nav>
    <app-main-menu></app-main-menu>
  </cam-layout-nav>
</app-layout-first-level>

<cam-pwa></cam-pwa>
