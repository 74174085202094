import { HttpErrorResponse } from '@angular/common/http';
import {Component, EventEmitter, Input, Output} from '@angular/core';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { IQuotationsService } from 'src/app/services/billings/IQuotationsService';
import {environment} from "../../../../../../../../../environments/environment";
import {ENotificationCode} from "@camelot/notification";

@Component({
  selector: 'app-quotation-version-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
})
export class SignedComponent extends BaseComponent{
  @Input()
  versionId!: string;

  @Input()
  quotationsService!: IQuotationsService;

  @Output()
  closed = new EventEmitter();

  public isValid: boolean = false;

  get quotationSignature$() {
    return this.quotationsService.quotationSignature.get$(this.versionId);
  }

  constructor() {
    super();
  }

  public cguAcceptation(value: boolean) {
    this.isValid = value;
  }
  public cancel() {
    this.closed.emit();
  }
  public send() {
    if(environment.FEATURES.youSign){
      this.signViaYouSign();
    } else {
      this.signViaGraal();
    }
  }

  private signViaGraal(){
    this.requestState.asked();
    this.quotationsService.setQuotationVersionSigned$(this.versionId).subscribe({
      complete: () => {
        this.requestState.completed();
        this._notificationService.addNotification(
          'billings.quotations.version.status.signed.success',
          ENotificationCode.success
        );
        this.closed.emit();
      },
      error: (error: HttpErrorResponse) => this.requestState.onError(error.status, error.statusText),
    });
  }

  private signViaYouSign(){
    const redirectUri = window.location.href;
    this.requestState.asked();
    this.quotationsService.getSignatureLink$(this.versionId,redirectUri, redirectUri, redirectUri).subscribe({
      complete: () => this.requestState.completed(),
      next: (quotationSignature) => {
        if(quotationSignature.link){
          window.open(quotationSignature.link, "_blank");
        }
      },
      error: (error: HttpErrorResponse) => this.requestState.onError(error.status, error.statusText),
    });
  }
}
