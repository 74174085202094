import { Component, OnInit } from '@angular/core';

import { CamRoutes } from '@camelot/menu';

import { EBillingsRoute } from '../../billings/routing.module';
import { EBillingsVisitorRoute } from '../../billings/visitor-routing.module';
import { BasePage } from '../../core/abstract/basePage';
import { EProjectsRoute } from '../../projects/routing.module';

type Scope = '' | 'projects' | 'invoices' | 'quotations' | 'visitor-quotations';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectPage extends BasePage implements OnInit {
  private _scope: Scope = '';
  private _projectId: string = '';
  private _invoiceId: string = '';
  private _quotationId: string = '';
  private _versionId: string = '';

  ngOnInit() {
    this._registerSubscription(
      this._getQueryParams({ scope: '', projectId: '', invoiceId: '', quotationId: '', versionId: '' }).subscribe(
        ({ scope, projectId, invoiceId, quotationId, versionId }) => {
          this._scope = <Scope>scope;
          this._projectId = projectId;
          this._invoiceId = invoiceId;
          this._quotationId = quotationId;
          this._versionId = versionId;
        }
      )
    );

    const urlParams = this._generateUrlParams();
    this._router.navigateByUrl(CamRoutes.getAbsoluteUrl(urlParams.enum, urlParams.data));
  }

  private _generateUrlParams() {
    switch (this._scope) {
      case 'projects':
        return {
          enum: [EProjectsRoute.projects, EProjectsRoute.view, EProjectsRoute.summary],
          data: {
            id: this._projectId ?? '',
          },
        };
      case 'invoices':
        return {
          enum: [EBillingsRoute.billing, EBillingsRoute.invoices, EBillingsRoute.view],
          data: {
            id: this._invoiceId ?? '',
          },
        };
      case 'quotations':
        return {
          enum: [EBillingsRoute.billing, EBillingsRoute.quotations, EBillingsRoute.view],
          data: {
            id: this._quotationId ?? '',
            version: this._versionId ?? 'latest',
          },
        };
      case 'visitor-quotations':
        return {
          enum: [EBillingsVisitorRoute.billing, EBillingsVisitorRoute.view],
          data: {
            version: this._versionId,
          },
        };
      default:
        return { enum: [], data: {} };
    }
  }
}
