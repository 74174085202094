import { Component, Input } from '@angular/core';

import { map, startWith } from 'rxjs/operators';

import { of } from 'rxjs';

import { CamUsersService, Permissions, User } from '@camelot/user';

import { IPrice } from '../../helpers/price';

export type Display = 'label' | 'amount' | 'both';

@Component({
  selector: 'app-billing-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
})
export class AmountComponent {
  @Input()
  amount!: IPrice;

  @Input()
  display: Display = 'both';

  @Input()
  inverse = false;

  @Input()
  forceTTC = false;

  get label$() {
    if (!Permissions.isAuthenticated) {
      return of('billings.amount.label.tva');
    }
    return this._userService.currentUser
      .get$()
      .pipe(map(user => (this._hideTva(user) ? 'billings.amount.label.htva' : 'billings.amount.label.tva')));
  }
  get amount$() {
    if (!Permissions.isAuthenticated) {
      return of(this.amount.incl);
    }
    return this._userService.currentUser
      .get$()
      .pipe(map(user => (this._hideTva(user) ? this.amount.excl : this.amount.incl)));
  }
  constructor(private _userService: CamUsersService) {}

  private _hideTva(user: User | null) {
    const isCompany = this.forceTTC ? user?.isCompany : false;

    return this.inverse ? !isCompany : isCompany;
  }
}
