import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { downloadFile } from '@camelot/utils';

import { BaseComponent } from 'src/app/modules/core/abstract/baseComponent';
import { IQuotationsService } from 'src/app/services/billings/IQuotationsService';
import { QuotationRow } from 'src/app/services/billings/dto/quotation/row';

import { BillingDetailService } from '../detail/detail.component';

@Component({
  selector: 'app-quotations-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent extends BaseComponent implements OnChanges {
  @Input()
  versionId!: string;

  @Input()
  quotationsService!: IQuotationsService;

  get version$() {
    return this.quotationsService.version.get$(this.versionId);
  }
  public readonly service: BillingDetailService = {
    get$: (id: string) => this.quotationsService.quotationParentRows.get$(id),
    fetchRow$: (id: string) => this.quotationsService.fetchQuotationParentRows$(id),
    fetchRowChildren$: (id: string, parent: QuotationRow) =>
      this.quotationsService.fetchQuotationRowChildren$(id, parent),
  };
  constructor() {
    super();
  }

  ngOnChanges() {
    this.quotationsService.fetchVersions$([this.versionId]).subscribe({
      complete: () => this.requestState.completed(),
      error: (error: HttpErrorResponse) => this.requestState.onError(error.status, error.statusText),
    });
  }

  public downloadFile(file: string) {
    downloadFile(file);
  }
}
