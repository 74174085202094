import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';

import { BasePage } from 'src/app/modules/core/abstract/basePage';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';
import { AppQuotationsVisitorService } from 'src/app/services/billings/visitor-quotations.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class QuotationVisitorViewPage extends BasePage implements OnInit {
  public version: string = '';

  public activeVersion: QuotationVersion | null = null;

  readonly quotationsService = inject(AppQuotationsVisitorService);

  constructor() {
    super();
  }

  ngOnInit() {
    this._registerSubscription(
      this._getPathParams({ id: '', version: '' }).subscribe(({ version }) => {
        this.version = version;
      })
    );
  }
}
