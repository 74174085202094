import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamLayoutModule, CamUiModule } from '@camelot/ui';
import { CamUserModule } from '@camelot/user';

import { AppCommonModule } from '../common/common.module';
import { AppMenuModule } from '../menu/menu.module';
import { LayoutContentComponent } from './layout-content/layout-content.component';
import { LayoutFirstLevelComponent } from './layout-first-level/layout-first-level.component';
import { LayoutLightComponent } from './layout-light/layout-light.component';
import { LayoutSecondLevelComponent } from './layout-second-level/layout-second-level.component';
import { LayoutTitleComponent } from './layout-title/layout-title.component';

@NgModule({
  declarations: [
    LayoutContentComponent,
    LayoutFirstLevelComponent,
    LayoutLightComponent,
    LayoutSecondLevelComponent,
    LayoutTitleComponent,
  ],
  imports: [AppCommonModule, AppMenuModule, CamIconsModule, CamLayoutModule, CamUiModule, CamUserModule, CommonModule],
  exports: [
    LayoutContentComponent,
    LayoutFirstLevelComponent,
    LayoutLightComponent,
    LayoutSecondLevelComponent,
    LayoutTitleComponent,
  ],
})
export class AppLayoutModule {}
