import { Component, Input } from '@angular/core';

import { IPrice } from 'src/app/modules/core/billings/helpers/price';
import { IQuotationsService } from 'src/app/services/billings/IQuotationsService';
import { QuotationVersion } from 'src/app/services/billings/dto/quotation/version';

@Component({
  selector: 'app-quotations-version-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class QuotationSummaryVersionComponent {
  @Input()
  version!: QuotationVersion;

  @Input()
  quotationsService!: IQuotationsService;

  get versionPrice(): IPrice {
    return {
      excl: this.version.exclVatTotal,
      incl: this.version.inclVatTotal,
    };
  }
}
