import { Injectable } from '@angular/core';

import { GraphEndpoint } from '@camelot/server';

import { IQuotationsService } from './IQuotationsService';
import { AppQuotationsService } from './quotations.service';

const graphEndpoint: GraphEndpoint = {
  clientName: 'quotationVisitorService',
  endpoint: 'quotation',
};

@Injectable({
  providedIn: 'root',
})
export class AppQuotationsVisitorService extends AppQuotationsService implements IQuotationsService {
  public override clientName = graphEndpoint.clientName;

  constructor() {
    super();

    super.registerRoutes({ graphEndpoint: graphEndpoint }, { visitor: true });
  }
}
